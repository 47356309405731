import React, { useEffect, useState } from 'react'
import Loader from '../../../component/loader/Loader'
import DataTable from '../../../component/data_table/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { MaterialIssueListRes, MaterialIssueListTodo } from '../../../redux/slices/store/material-issue/MaterialIssueList'

export default function MaterialIssueList() {
    const [loading, setLoading] = useState(false)
    const [bool, setBool] = useState(false)
    const materialList = useSelector(state => state?.MaterialIssueList?.data?.data)
    const dispatch = useDispatch()

    useEffect(() => {
        setLoading(true)
        dispatch(MaterialIssueListTodo('')).then(() => customerFun())
    }, [bool])

    const customerFun = () => {
        if (MaterialIssueListRes && MaterialIssueListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }



    const columnDefs = [
        {
            headerName: 'S.NO',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true,
            maxWidth: 80
        },
        {
            headerName: 'SKF ITEM NAME',
            field: 'vitem_description',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 200
            // wrapText: true,
            // autoHeight: true,
        },


        {
            headerName: 'OPIL ITEM NAME',
            field: 'vitem_name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 200
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'OPIL ITEM CODE',
            field: 'vdrawing_code',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 250,
            // wrapText: true,
            // autoHeight: true,
        },

        {
            headerName: 'ISSUED QTY',
            field: 'mstock',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 150
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'ISSUED DATE',
            field: 'dissued_date',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 150
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'SALES ORDER CODE',
            field: 'vpurchase_order_code',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 250
            // wrapText: true,
            // autoHeight: true,
        },
        {
            headerName: 'REMARK',
            field: 'vremark',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            maxWidth: 500,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },




        // {
        //     field: 'ACTIONS',
        //     cellRenderer: ReceiveMaterialAction,
        //     cellRendererParams: {
        //         bool: bool,
        //         setBool: setBool,
        //         setLoading: setLoading,
        //     },
        //     // wrapText: true,
        //     autoHeight: true,
        //     pinned: 'right',
        // }
    ]
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className='layout_head'>
                            <h5>Material Issue List</h5>
                        </div>
                        {loading ?
                            <div className='d-flex juistify-content-center mt-5 '>
                                <Loader />
                            </div> :
                            materialList?.length ?
                                <>

                                    <div className="col-md-12 mt-1">
                                        <DataTable
                                            rowData={materialList}
                                            columnDefs={columnDefs}
                                            height={'75vh'}
                                        />

                                    </div>

                                </>
                                :
                                <div className='empty_section'>
                                    <span>DATA NOT FOUND</span>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
