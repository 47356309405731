import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardRes, dashboardTodo } from '../../redux/slices/DashboardSlice/dashboard'
import Loader from '../../component/loader/Loader'
import { FaBorderAll, FaBoxes } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export default function Dashboard() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const dashboardData = useSelector(state => state?.Dashboard?.data?.data?.[0])

    var type = localStorage.getItem('type')

    useEffect(() => {
        setLoading(true)
        dispatch(dashboardTodo()).then(() => dashboardFun())
    }, [])

    const dashboardFun = () => {
        if (dashboardRes && dashboardRes.success) {
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="layout_head">
                    <h5>Dashboard</h5>
                </div>
                {loading ?
                    <div className='d-flex justify-content-center mt-5 pt-5'>
                        <Loader />
                    </div> :
                    <div className="row">
                        <div className="col-md-3 mt-3">
                            <Link to='/stock-report-list'>
                                <div className="card dashboard_card text-center  h-100" >
                                    <div className='d-flex justify-content-around align-items-center '>
                                        <div className=''>
                                            <span className='dashboard_icon'>
                                                < FaBoxes size={45} style={{ color: "#105684" }} />
                                            </span>
                                        </div>
                                        <div className=''>
                                            <h4>STOCK</h4>
                                            <h3 className='text-center'>{dashboardData && dashboardData.TotalStocks}</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 mt-3">
                            <Link to='/material-issue-list'>
                                <div className="card dashboard_card text-center  h-100" >
                                    <div className='d-flex justify-content-around align-items-center '>
                                        <div className=''>
                                            <span className='dashboard_icon'>
                                                < FaBorderAll size={40} style={{ color: "#105684" }} />
                                            </span>
                                        </div>
                                        <div className=''>
                                            <h4> MATERIAL ISSUE</h4>
                                            <h3 className='text-center'>{dashboardData && dashboardData.TotaIssueMaterials}</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-3 mt-3">
                            <Link to="/pending-material-list">  
                               <div className="card dashboard_card text-center  h-100" >
                                <div className='d-flex justify-content-around align-items-center '>
                                    <div className=''>
                                        <span className='dashboard_icon'>
                                            < FaBoxes size={45} style={{ color: "#105684" }} />
                                        </span>
                                    </div>
                                    <div className=''>
                                        <h4>PENDING MATERIAL</h4>
                                        <h3 className='text-center'>{dashboardData && dashboardData.TotalPendingMaterials}</h3>
                                    </div>
                                </div>
                            </div></Link>
                        </div>

                    </div>}


            </div>


        </>
    )
}
