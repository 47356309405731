import './App.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';
import '../src/assest/style/component_css/Button.css'
import '../src/assest/style/component_css/Form.css'
import '../src/assest/style/data_table/DataTable.css'
import '../src/assest/style/pages_css/LoginPage.css'
import '../src/assest/style/pages_css/Navbar.css'
import '../src/assest/style/pages_css/Details.css'
import '../src/assest/style/pages_css/Dashboard.css'
import '../src/assest/style/pages_css/Sidebar.css'
import '../src/assest/style/pages_css/LoginPage.css'
import '../src/assest/style/pages_css/AddOrder.css'
import '../src/assest/style/pages_css/Layout.css'
import Login from './pages/login/Login';
import ProtectedRoutes from './ProtectedRoute';
import Dashboard from './pages/dashboard/Dashboard';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import ReceiveMateriaList from './pages/store/recieve-material/ReceiveMateriaList';
import ReceiveMaterialDetails from './pages/store/recieve-material/ReceiveMaterialDetails';
import AddMaterialIssue from './pages/store/material-issue/AddMaterialIssue';
import MaterialIssueList from './pages/store/material-issue/MaterialIssueList';
import ReceiveItemList from './pages/store/stock-list/ReceiveItemList';
import ReceiveReportList from './pages/store/report-list/ReceiveReportList';
import IssuedReportList from './pages/store/report-list/IssuedReportList';
import StockReportList from './pages/store/report-list/StockReportList';
import PendingMaterialList from './pages/store/material-issue/PendingMaterialList';

function App() {
  return (
    <div className="App">
      {/* <Login /> */}
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route element={<ProtectedRoutes  />}>
        <Route path='/' element={<Navigate replace to='/dashboard' />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/receive-material' element={<ReceiveMateriaList />} />
          <Route path='/receive-material-details' element={<ReceiveMaterialDetails />} />
          <Route path='/receive-item-list' element={<ReceiveItemList />} />
          <Route path='/material-issue' element={<AddMaterialIssue />} />
          <Route path='/material-issue-list' element={<MaterialIssueList />} />
          <Route path='/pending-material-list' element={<PendingMaterialList />} />
          <Route path='/receive-report-list' element={<ReceiveReportList/>} />
          <Route path='/issued-report-list' element={<IssuedReportList/>} />
          <Route path='/stock-report-list' element={<StockReportList/>} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
